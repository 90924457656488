/* eslint-disable import/first */
require("@rails/actiontext");

import "../initializers/rails-ujs";
import "../initializers/turbolinks";
import "../initializers/jquery";
import "../initializers/trix";
import "../initializers/stimulus";
import "../initializers/moment";
import "../initializers/sentry";
import "../initializers/ie-detect";
import "../initializers/polyfills";
import "../initializers/chartjs-plugins";
import "../initializers/selectize-custom-plugin";
import "../css/application.css"; // Load our CSS after all Stimulus controllers (which may pull in library-specific base styles)

window.Scout = {};
