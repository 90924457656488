/* built from suggestion at https://github.com/selectize/selectize.js/issues/249#issuecomment-75052153 */

/* eslint-disable func-names */
import Selectize from "@selectize/selectize";

// eslint-disable-next-line no-unused-vars
Selectize.define("silent_remove", function (_) {
  const self = this;

  this.on("item_remove", function () {
    this.plugin_silent_remove_in_remove = true;
  });

  this.search = (function () {
    const original = self.search;

    return function () {
      if (typeof this.plugin_silent_remove_in_remove !== "undefined") {
        delete this.plugin_silent_remove_in_remove;
        return {
          items: {},
          query: [],
          tokens: [],
        };
      } else {
        // eslint-disable-next-line prefer-rest-params
        return original.apply(this, arguments);
      }
    };
  })();
});
