import Trix from "trix";

class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }
}

function innerHTML(alignment) {
  return `
    <style>
      :host {
        text-align: ${alignment};
        width: 100%;
        display: block;
      }
    </style>

    <slot></slot>
  `;
}
export class AlignCenterElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML("center");
  }
}

export class AlignRightElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML("right");
  }
}

export class AlignLeftElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML("left");
  }
}

window.customElements.define("align-left", AlignLeftElement);
window.customElements.define("align-center", AlignCenterElement);
window.customElements.define("align-right", AlignRightElement);

document.addEventListener("trix-before-initialize", () => {
  Trix.config.textAttributes.underline = {
    tagName: "u",
    inheritable: true,
  };

  Trix.config.textAttributes.highlight = {
    tagName: "mark",
    inheritable: true,
  };

  Trix.config.blockAttributes.alignCenter = {
    tagName: "align-center",
    nestable: false,
    exclusive: true,
  };

  Trix.config.blockAttributes.alignLeft = {
    tagName: "align-left",
    nestable: false,
    exclusive: true,
  };

  Trix.config.blockAttributes.alignRight = {
    tagName: "align-right",
    nestable: false,
    exclusive: true,
  };
});

// Prevent Trix from accepting drag and drop file attachments
document.addEventListener("trix-file-accept", e => {
  e.preventDefault();
});

document.addEventListener("trix-action-invoke", event => {
  const { editor } = event.target;

  if (event.actionName === "x-reset") {
    editor.recordUndoEntry("Cleared Annotations");
    editor.setSelectedRange([0, editor.getDocument().getLength()]);
    editor.deactivateAttribute("strike");
    editor.setSelectedRange([0, editor.getDocument().getLength()]);
    editor.deactivateAttribute("highlight");
    editor.setSelectedRange([0, 0]);
  }
});
